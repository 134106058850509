<template>
  <div class="price_component">
    <p class="visually-hidden">{{ accessiblePriceLabel }}</p>
    <div
      :class="{ large: large, medium: medium }"
      class="price"
      :style="{ color: color }"
    >
      <div class="euro" :aria-hidden="true">
        {{ packPriceEuro }}
      </div>
      <div class="column">
        <div class="cent">
          <span :aria-hidden="true">{{ packPriceCent }}€</span>
          <Footnote
            v-if="footnote"
            class="price__note"
            :footnote-id="footnote"
            :tariff-slug="tariffSlug"
          />
        </div>
        <div v-if="period" class="period" :aria-hidden="true">
          {{ periodText }}
        </div>
        <div v-else-if="noRecurringCosts" class="period" :aria-hidden="true">
          {{ noRecurringCosts }}
        </div>
        <div v-if="initialCost" class="period" :aria-hidden="true">
          {{ initialCost }}€ einmalig
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Footnote from 'src/components/common/footnote/footnote.vue'
export default {
  name: 'PackPrice',
  components: {
    Footnote
  },
  props: {
    price: {
      type: Number,
      default: undefined
    },
    formattedEuro: {
      type: [String, Number],
      default: undefined
    },
    formattedCent: {
      type: String,
      default: undefined
    },
    period: {
      type: String,
      default: undefined
    },
    initialCost: {
      type: String,
      default: undefined
    },
    noRecurringCosts: {
      type: String,
      default: undefined
    },
    color: {
      type: String,
      default: undefined
    },
    large: {
      type: Boolean,
      default: false
    },
    medium: {
      type: Boolean,
      default: false
    },
    footnote: {
      type: Number,
      default: undefined
    },
    tariffSlug: {
      type: String,
      default: undefined
    },
    isPack: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    accessiblePriceLabel() {
      return `${this.packPriceEuro},${this.packPriceCent} € ${
        this.period ? 'pro ' + this.period : ''
      } `
    },
    periodText() {
      return this.isPack ? 'je ' + this.period : '/' + this.period
    },
    packPriceEuro() {
      if (this.formattedEuro) {
        return this.formattedEuro
      }

      if (this.price || this.price === 0) {
        return parseInt(this.price)
      } else {
        return '...'
      }
    },
    packPriceCent() {
      if (this.formattedCent) {
        return this.formattedCent
      }

      if (this.price) {
        const value = parseInt(
          Math.ceil((this.price - this.packPriceEuro).toFixed(2) * 100)
        )

        return value === 0 ? '' : value
      }

      return ''
    }
  }
}
</script>

<style lang="scss" scoped>
.price_component {
  @include font-normal;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @include breakpoint($up-to-desktop) {
    margin-left: 10px;
  }
}

.price {
  display: flex;
  line-height: 0.8;
  height: calc-rem(35px);
  white-space: nowrap;

  .euro {
    @include font-bold;

    font-size: calc-rem(48px);
  }

  .cent {
    position: relative;
    font-size: 1rem;
  }

  .period {
    font-size: calc-rem(10px);
    margin-top: auto;
    margin-left: 2px;
  }

  .column {
    margin-top: 3px;
    margin-left: 4px;
  }

  &.large {
    line-height: 1;
    height: calc-rem(80px);

    .euro {
      font-size: 5.4rem;
    }

    > .column {
      .cent {
        @include font-bold;

        font-size: 2.6rem;
        margin-top: 6px;
      }

      .period {
        margin-top: 3px;
        font-size: 1.4rem;
        margin-left: 8px;
      }
    }
  }

  &.medium {
    height: calc-rem(50px);

    .euro {
      font-size: 3.7rem;
    }

    > .column {
      margin-left: 6px;

      .cent {
        @include font-bold;

        font-size: 1.6rem;
        margin-top: 2px;
      }

      .period {
        margin-top: 6px;
        font-size: 0.9rem;
      }
    }
  }
}

.price__recurring {
  margin-right: 20px;
}

.price__note {
  position: absolute;
  top: 0.3rem;
  margin-left: 6px;
}
</style>
